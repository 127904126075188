import { NavigationItem } from "./NavigationItem";
import {
	NamadaIcon,
	CeloIcon,
	CeloBaklavaIcon,
	NymIcon,
} from "../Common/Icons/svgs";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EditNotificationsIcon from "@mui/icons-material/EditNotifications";
import SystemSecurityUpdateGoodIcon from "@mui/icons-material/SystemSecurityUpdateGood";
import WidgetsIcon from "@mui/icons-material/Widgets";
import {
	CeloNetworkKey,
	NamadaNetworkKey,
	NymNetworkKey,
} from "../Common/Models/CustomTypes";

export const navigationList: NavigationItem[] = [
	{
		title: "Celo",
		path: "",
		icon: <CeloIcon />,
		subItems: [
			{
				title: "Block Map",
				path: "/celo/block-map",
				icon: <WidgetsIcon />,
				parentCode: CeloNetworkKey,
			},
			{
				title: "Attestation Map",
				path: "/celo/attestations",
				icon: <SystemSecurityUpdateGoodIcon />,
				parentCode: CeloNetworkKey,
			},
			{
				title: "Metrics",
				path: "/celo/metrics",
				icon: <NotificationsIcon />,
				parentCode: CeloNetworkKey,
			},
			{
				title: "Subscriptions",
				path: "/celo/subscriptions",
				icon: <EditNotificationsIcon />,
				parentCode: CeloNetworkKey,
				requireAuthentication: true,
			},
		],
	},
	{
		title: "Celo Baklava",
		path: "",
		icon: <CeloBaklavaIcon />,
		subItems: [
			{
				title: "Block Map",
				path: "/celo-baklava/block-map",
				icon: <WidgetsIcon />,
				parentCode: CeloNetworkKey,
			},
			{
				title: "Attestation Map",
				path: "/celo-baklava/attestations",
				icon: <SystemSecurityUpdateGoodIcon />,
				parentCode: CeloNetworkKey,
			},
			{
				title: "Metrics",
				path: "/celo-baklava/metrics",
				icon: <NotificationsIcon />,
				parentCode: CeloNetworkKey,
			},
			{
				title: "Subscriptions",
				path: "/celo-baklava/subscriptions",
				icon: <EditNotificationsIcon />,
				parentCode: CeloNetworkKey,
				requireAuthentication: true,
			},
		],
	},
	{
		title: "NYM",
		path: "",
		icon: <NymIcon />,
		subItems: [
			{
				title: "Block Map",
				path: "/nym/block-map",
				icon: <WidgetsIcon />,
				parentCode: NymNetworkKey,
			},
			{
				title: "Metrics",
				path: "/nym/metrics",
				icon: <NotificationsIcon />,
				parentCode: NymNetworkKey,
			},
			{
				title: "Subscriptions",
				path: "/nym/subscriptions",
				icon: <EditNotificationsIcon />,
				parentCode: NymNetworkKey,
				requireAuthentication: true,
			},
		],
	},
	{
		title: "Namada",
		path: "",
		icon: <NamadaIcon />,
		subItems: [
			{
				title: "Block Map",
				path: "/namada/block-map",
				icon: <WidgetsIcon />,
				parentCode: NamadaNetworkKey,
			},
			{
				title: "Metrics",
				path: "/namada/metrics",
				icon: <NotificationsIcon />,
				parentCode: NamadaNetworkKey,
			},
			{
				title: "Subscriptions",
				path: "/namada/subscriptions",
				icon: <EditNotificationsIcon />,
				parentCode: NamadaNetworkKey,
				requireAuthentication: true,
			},
		],
	},
];
