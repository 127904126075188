import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { getConfigForNetwork } from "./Utils/config";
import App from "./App";

export default function Auth0ProviderWithNavigate(): JSX.Element {
	let AuthUrl = "vladiatorlabs.eu.auth0.com";
	let AuthClientId = "DreHAuit9LX9NbY68RQpXsqMYP9jtezL";
	const navigate = useNavigate();

	if (window.location.hostname === "localhost") {
		console.log("Running in localhost, using staging Auth0 tenant");
		AuthUrl = "dev-e4qmxpo7.us.auth0.com";
		AuthClientId = "CTT0l9dTzpsr0EAM7iqyv992gr1G8zUd";
	} else if (window.location.hostname === "dev.vido.atalma.io") {
		console.log(
			"Running on development server, using production Auth0 tenant",
		);
		AuthUrl = "vladiatorlabs.eu.auth0.com";
		AuthClientId = "DreHAuit9LX9NbY68RQpXsqMYP9jtezL";
	} else
		console.log(
			"Running on production server, using production Auth0 tenant",
		);

	const onRedirectCallback = (appState?: AppState) => {
		if (appState?.returnTo) {
			navigate(`${appState?.returnTo}`);
		} else {
			navigate(`${window.location.pathname}`);
		}
	};

	let audience: string;
	if (window.location.origin.includes("baklava"))
		audience = getConfigForNetwork("baklava").apiAudience;
	else if (window.location.origin.includes("mainnet"))
		audience = getConfigForNetwork("mainnet").apiAudience;
	else if (window.location.origin.includes("namada"))
		audience = getConfigForNetwork("namada").apiAudience;
	else if (window.location.origin.includes("nym"))
		audience = getConfigForNetwork("nym").apiAudience;
	else audience = getConfigForNetwork("mainnet").apiAudience;

	return (
		<Auth0Provider
			domain={AuthUrl}
			clientId={AuthClientId}
			useRefreshTokens={false}
			cacheLocation={"localstorage"}
			onRedirectCallback={onRedirectCallback}
			authorizationParams={{
				redirect_uri: window.location.origin,
				scope: "read:subscriptions profile email openid",
				audience: audience,
			}}
		>
			<App appName="" />
		</Auth0Provider>
	);
}
